<template>
    <div>
        <b-card no-body>
              <div class="mt-1">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon float-right" v-on:click="addtemplate">
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <h5 class="mt-1">Add your own Information</h5>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Text</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in content" :key="index + 'addyourown'">
                      <th>
                        <b-form-input class="mt-1" v-model="content[index].label" trim :key="index + 'label'" placeholder="Enter your Label" />
                      </th>
                      <th>
                        <b-form-input class="mt-1" v-model="content[index].value" trim :key="index + 'value'" placeholder="Enter your Text" />
                      </th>
                      <th>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="mr-1 mt-1" variant="outline-danger" v-on:click="removetemplate(index)">
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card>
    </div>
</template>wer

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
} from 'bootstrap-vue'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    amount
} from '@validations'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
    props: ['name', 'valuex', 'keys', 'label', 'options', 'id', 'validations'],
    components: {
        BCol,
        BCard,
        BRow,
        BForm,
        BCardBody,
        BFormGroup,
        BFormCheckbox,
        BFormTextarea,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BFormDatepicker,
        vSelect,
    },
    directives: {
        Ripple,
    },
    setup(props, {
        emit
    }) {
        const {
            refFormObserver,
            getValidationState
        } = formValidation();
        return {
            refFormObserver,
            getValidationState,
        };
    },
    data() {
        return {
            required,
            amount,
            content:this.valuex??[],
        }
    },
    mounted() {
      this.$watch('$props', ()=>{
            this.content=this.valuex
        }, { deep: true })
        this.changevalue(this.content)
    },
    methods: {
    changevalue(content) {
      if (content != null) {
        this.$emit("updatedata", content);
      }
    },
        
      addtemplate() {
        if (this.content.length < 5) {
          var template = {
            label: "",
            value: "",
          };
          this.content.push(template);
        } else {
          this.$swal({
            icon: "error",
            title: "Limit Reached!",
            text: "Sorry only 5 information can be added.",
          });
        }
      },
      removetemplate(index) {
        this.$swal({
          title: "Are you sure you want to delete?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.content.splice(index, 1);
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Information has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
      },
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
